import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Observable } from "rxjs";

@Component({
  selector: 'text-detection',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class TextDetection extends BaseComponent {
  protected _imgUrl: any = null;
  @Input() set imgUrl(value) {
    this._imgUrl = value;
    this.listCoordinates = null;
    //get image size when image url change
    this.getImageSize(this.getImgUrl()).subscribe(response => {
      this.imgHeight = response.height;
      this.imgWidth = response.width;
    });
  }
  get imgUrl() { return this._imgUrl }

  private _model: any = null;
  @Input() set model(value) {
    this._model = value;
    //đẩy việc load model vào cuối main thread để tránh block DOM
    //Làm cho việc hiển thị smooth hơn
    setTimeout(() => {
      this.loadListCoordinates();
    }, 20)

  }
  get model() { return this._model }

  @Output() onWordClick = new EventEmitter<any>();

  public listCoordinates: any = null;
  public imgWidth: number = 0;
  public imgHeight: number = 0;
  public selectedWord: any = null;


  getImageSize(url: string): Observable<any> {
    return new Observable(observer => {
      var image = new Image();
      image.src = url;

      image.onload = () => {
        var height = image.height;
        var width = image.width;

        observer.next({
          width: width,
          height: height
        });
        observer.complete();
      };
    });
  }

  getImgUrl() {
    return this.imgUrl?.changingThisBreaksApplicationSecurity;
  }

  loadListCoordinates() {
    this.listCoordinates = this.model?.words?.filter((it) => it.BlockType === 'WORD') || [];
  }

  onWordBlockClick(item) {
    this.selectedWord = item;
    console.log("selected", item)
    this.onWordClick?.emit(item.Text);
  }

  getTopStyle(item) {
    let value = <number>item.Geometry?.BoundingBox?.Top * this.imgHeight;
    return value.toFixed(0);
  }
  getLeftStyle(item) {
    let value = <number>item.Geometry?.BoundingBox?.Left * this.imgWidth;
    return value.toFixed(0);
  }
  getWidthStyle(item) {
    let value = <number>item.Geometry?.BoundingBox?.Width * this.imgWidth;
    return value.toFixed(0);
  }
  getHeightStyle(item) {
    let value = <number>item.Geometry?.BoundingBox?.Height * this.imgHeight;
    return value.toFixed(0);
  }
}